<mat-toolbar
  color="primary"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  Transfer asset
</mat-toolbar>
<div class="basic-container">
  <form [formGroup]="form" (submit)="initiate()">
    <div id="content" fxLayout="column" fxLayoutGap="16px">
      <h3>
        Please enter the required information to transfer your asset
      </h3>
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="3.5%"
        fxLayoutAlign="left"
      >
        <mat-form-field class="example-full-width" [appearance]="'fill'" style="padding-top: 5.5px;">
          <mat-label>Receiver Address</mat-label>
          <input matInput placeholder="e.g. 0xdCDFc6A8D4DC39A26fB63dc409f20b20107d7a1F" formControlName="to" />
        </mat-form-field>
        <mat-form-field class="example-full-width" [appearance]="'fill'" style="padding-top: 5.5px;">
          <mat-label>Amount</mat-label>
          <input matInput type="number" placeholder="Transfer amount" formControlName="amount" />
        </mat-form-field>
      </div>
      <mat-expansion-panel *ngIf="this.isZeroToken">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Spending Conditions
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div style="margin-bottom: 2.5em">
          <button mat-button color="primary" type="button" (click)="addConditionLayer()">
            <mat-icon>add box</mat-icon>
            Add Condition Layer
          </button>
        </div>

        <div fxLayout="row"
             fxLayout.xs="column"
             fxLayoutAlign="space-evenly center" *ngIf="txConditions.length !== 0">
          <mat-form-field [appearance]="'fill'">
            <mat-label>Condition Layer</mat-label>
            <mat-select placeholder="e.g, Condition Layer 1" formControlName="conditionLayerIndex">
              <mat-option *ngFor="let layer of existingLayers" [value]="layer.split(' ').pop()">
                {{ layer }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [appearance]="'fill'">
            <mat-label>Subject</mat-label>
            <mat-select placeholder="e.g, Sender" formControlName="subject">
              <mat-option *ngFor="let sub of subjects" [value]="sub">
                {{ sub }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [appearance]="'fill'">
            <mat-label>Name</mat-label>
            <input matInput placeholder="e.g, Age" formControlName="name" />
          </mat-form-field>
          <mat-form-field [appearance]="'fill'">
            <mat-label>Predicate</mat-label>
            <mat-select placeholder="e.g, greater than" formControlName="predicate">
              <mat-option *ngFor="let pred of predicates" [value]="pred.value">
                {{ pred.view }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [appearance]="'fill'">
            <mat-label>Value</mat-label>
            <input matInput placeholder="e.g, 18" formControlName="value" />
          </mat-form-field>
          <button mat-button color="primary" type="button" (click)="addCondition()">
            <mat-icon>add box</mat-icon>
            Attach New Condition
          </button>
        </div>

        <div fxLayout="column"
             fxLayoutAlign="space-between"
             *ngFor="let layer of this.txConditions; let layerIndex=index" [formGroupName]="layerIndex">
          <mat-card >
            <mat-card-header>
              <mat-card-title-group>
                <mat-card-title>
                  Condition Layer {{ layerIndex + 1 }}
                </mat-card-title>
              </mat-card-title-group>
              <button mat-button color="primary" type="button" (click)="removeConditionLayer(layerIndex)">
                <mat-icon>close</mat-icon>
              </button>
            </mat-card-header>
            <mat-card-content>
              <p *ngIf="txConditions[layerIndex].length === 0"> No conditions added to this layer.</p>
              <div *ngIf="txConditions[layerIndex].length !== 0">
                <table mat-table [dataSource]="txConditions[layerIndex]" class="mat-elevation-z8">
                  <ng-container matColumnDef="subject">
                    <th mat-header-cell *matHeaderCellDef> Subject </th>
                    <td mat-cell *matCellDef="let condition"> {{condition.subject}} </td>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let condition"> {{condition.name}} </td>
                  </ng-container>
                  <ng-container matColumnDef="predicate">
                    <th mat-header-cell *matHeaderCellDef> Predicate </th>
                    <td mat-cell *matCellDef="let condition"> {{condition.predicate}} </td>
                  </ng-container>
                  <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef> Value </th>
                    <td mat-cell *matCellDef="let condition"> {{condition.value}} </td>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let condition">
                      <button mat-button color="primary" type="button" fxFlex="nogrow" (click)="removeCondition(layerIndex, txConditions[layerIndex].findIndex(condition))">
                        Remove Condition
                      </button>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: auto;"></tr>
                </table>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-expansion-panel>
      <button mat-button color="primary" type="submit" [disabled]="form.invalid">Transfer</button>
      <button mat-button type="button" (click)="cancel()">Cancel</button>
    </div>
  </form>
</div>
