import {Component, OnInit} from '@angular/core';
import {firstValueFrom, Subscription} from "rxjs";
import {MessagingService} from "./messaging.service";
import {AssetsService} from "./assets.service";
import {PresentationService} from "./credentials/presentation.service";
import {MatDialog} from "@angular/material/dialog";
import {CredentialsSelectComponent} from "./credentials/credentials-select/credentials-select.component";

@Component({
  selector: 'ssi-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  private pubKeySubscription: Subscription = new Subscription();
  private querySubscription: Subscription = new Subscription();

  constructor(private messagingService: MessagingService,
              private assetsService: AssetsService,
              private presentationService: PresentationService,
              private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if(this.assetsService.getPrivateKey() === ''){
      this.pubKeySubscription.add(this.assetsService.publicKey$.subscribe(
        (pubKey) => {
          if (pubKey !== undefined) {
            // The value is now set, perform your actions here
            this.pubKeySubscription.unsubscribe();
            console.log("Starting querying server...");
            this.startQuerying();
          }
        }
      ));
    } else {
      console.log("Found a public key, starting querying server...");
      this.startQuerying();
    }
  }

  startQuerying(){
    this.querySubscription = this.messagingService.startQueryingProofs().subscribe({
      next: async (result) => {
        const proofRequests = result['proofRequests'] || [];
        if (proofRequests.length != 0) {
          for(let i = 0; i < proofRequests.length; i++){
            const sessionId = proofRequests[i]['sessionId'];
            await this.presentationService.authRequest(proofRequests[0]['proofRequestLink'].split("scan#")[1], sessionId);
            while(this.presentationService.res === undefined){
              await new Promise(r => setTimeout(r, 100));
            }
            if (this.presentationService.res) {
              const proceed = await firstValueFrom(
                this.dialog
                  .open(CredentialsSelectComponent, {
                    data: true,
                    disableClose: true,
                  })
                  .afterClosed()
              );
              if (!proceed) {
                await this.messagingService.failedProofRequest(sessionId);
                console.log("Declined to send a proof.");
              } else {
                console.log("Sent proof.");
              }
            }
          }
        }
      },
      error: (error) => {
        console.error('Error querying server:', error);
      }
    });
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    this.pubKeySubscription.unsubscribe();
    this.querySubscription.unsubscribe();
  }

  // showPopup(result: any): void {
  //   // Implementation to show popup
  // }
}
