<mat-toolbar
  color="primary"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  Assets
</mat-toolbar>
<div id="content" fxLayout="column" fxLayoutGap="16px">
  <div style="align-items: center;" >
    <h3>Your address: {{getPublicKey()}}</h3>
    <button mat-button style="float:left;" color="primary" (click)="getZeroTokens()">
      <mat-icon>account_balance_wallet</mat-icon>
      Request Zero Tokens
    </button>
    <a mat-button style="float:left;" color="primary" href="https://www.alchemy.com/faucets/polygon-mumbai" target="_blank">
      <mat-icon>polymer</mat-icon>
      Request MATIC
    </a>
  </div>
  <mat-divider></mat-divider>
</div>

  <div class="cards" fxLayout="row wrap" fxLayoutAlign="stretch" fxLayoutGap="10px">
      <mat-card class="asset-card"
                [fxFlex]="(100/6) + '%'"
                fxFlex.xs="100%"
                fxFlex.sm="33%"
                fxLayout="column"
                fxLayoutAlign="space-between stretch"
                *ngFor="let asset of assets" >
        <mat-card-header>
          <mat-card-title>{{ asset.name }}</mat-card-title>
          <mat-card-subtitle>{{ asset.desc }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <p>
            Balance: {{ asset.balance }} Euro
          </p>
        </mat-card-content>
        <mat-card-actions align="end">
          <button style="float:right;" mat-button color="primary" (click)="routeTransfer(asset.id)">
          Transfer
          </button>
        </mat-card-actions>
      </mat-card>
</div>
