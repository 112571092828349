import { Injectable } from '@angular/core';
import {ethers} from "ethers";

import programmableMoneyContractData from '../assets/artifacts/ProgrammableMoney.json';
import conditionsContractData from '../assets/artifacts/Conditions.json';
import {environment} from "../environments/environment";
import {BehaviorSubject} from "rxjs";

export interface Asset {
  id: string;
  name: string,
  desc: string;
  balance: number;
}


@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  private keyStorage = 'key';
  private publicKey = new BehaviorSubject<string | undefined>(undefined);

  constructor() {}

  public getPrivateKey(): string {
    return localStorage.getItem(this.keyStorage) || '';
  }

  public setPrivateKey(key: string) {
    localStorage.setItem(this.keyStorage, key);
  }

  public generatePrivateKey() {
    return ethers.Wallet.createRandom().privateKey;
  }

  public getPublicKeyFromPrivateKey(privateKey: string){
    return new ethers.Wallet(privateKey).address;
  }

  public setPublicKey(){
    this.publicKey.next(this.getPublicKeyFromPrivateKey(this.getPrivateKey()));
  }

  get publicKey$() {
    return this.publicKey.asObservable();
  }

  public getProvider(){
    return new ethers.JsonRpcProvider(environment.polygonConfig.network);
  }

  public getWalletWithProvider(){
    return new ethers.Wallet(this.getPrivateKey(), this.getProvider());
  }

  public getContractData(contractName: string) {
    let contractData;
    if (contractName === 'pm') {
      contractData = programmableMoneyContractData.programmableMoneyContractData;
    } else {
      contractData = conditionsContractData.conditionsContractData;
    }
    return contractData;
  };

  public getContract(contractName: string) {
    const contractData = this.getContractData(contractName);
    return new ethers.Contract(ethers.getAddress(contractData.address), contractData.abi, this.getWalletWithProvider());
  }

  async call(contractName: string, functionName: string, args: any[]){
    return (this.getContract(contractName))[functionName](...args);
  }

  public getZeroTokenBytes32(){
    return ethers.ZeroAddress;
  }

  public formatBytes32String(text: string){
    return ethers.encodeBytes32String(text);
  }

  public zeroPadValue(text: string){
    return ethers.zeroPadValue(text, 32);
  }

  public getAddress(address: string) {
    return ethers.getAddress(address);
  }

  // todo below functions for demo only, remove for actual implementation
  public getWalletWithMasterProvider(){
    return new ethers.Wallet('598c35ede2d791c7c1a3370d276ca30fb58d963bbca3e816ecaa6c900f3f8e7a', this.getProvider());
  }

  public getMasterContract(contractName: string) {
    const contractData = this.getContractData(contractName);
    return new ethers.Contract(ethers.getAddress(contractData.address), contractData.abi, this.getWalletWithMasterProvider());
  }

  async getZeroTokens(contractName: string, functionName: string, args: any[]){
    return (this.getMasterContract(contractName))[functionName](...args);
  }

}
