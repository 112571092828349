import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {firstValueFrom, interval, Observable, switchMap} from 'rxjs';
import {environment} from "../environments/environment";
import {startWith} from "rxjs/operators";
import {AssetsService} from "./assets.service";

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  private headers;
  private httpOptions;
  constructor(private http: HttpClient,
              private assetsService: AssetsService) {
    this.headers = new HttpHeaders();
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Authorization', 'Basic ' + Buffer.from(environment.verifierConfig.username + ":" + environment.verifierConfig.password).toString('base64'));
    this.httpOptions = {
      headers: this.headers
    };
  }

  startQueryingProofs(): Observable<any> {
    return interval(environment.serverQueryRate).pipe(
      startWith(0),
      switchMap(() => this.queryProofRequests())
    );
  }

  queryProofRequests(): Observable<any> {
    const pubKey = this.assetsService.getPublicKeyFromPrivateKey(this.assetsService.getPrivateKey());
    return this.http.get<any>(environment.verifierConfig.host + '/proofRequests/' + pubKey);
  }

  querySession(sessionId: string) {
    return this.http.post(environment.verifierConfig.host + '/session',
      { sessionId: sessionId }, this.httpOptions);
  }

  async registerVerifier() {
    const pubKey = this.assetsService.getPublicKeyFromPrivateKey(this.assetsService.getPrivateKey());
    return await firstValueFrom(this.http.post<any>(environment.verifierConfig.host + '/register',
      { publicKey: pubKey }, this.httpOptions
    ));
  }

  async initiateTransaction(walletAddr: string, to: string, id: string) {
    console.log("Starting authorization process...");
    return await firstValueFrom(this.http.post<any>(environment.verifierConfig.host + '/initiate',
      { from: walletAddr, to: to, tokenId: id }, this.httpOptions
    ));
  }

  async failedProofRequest(sessionId: string){
    console.log("making request");
    const pubKey = this.assetsService.getPublicKeyFromPrivateKey(this.assetsService.getPrivateKey());
    return await firstValueFrom(this.http.post<any>(environment.verifierConfig.host + '/proofRequests/' + pubKey,
      {sessionId: sessionId}, this.httpOptions
    ));
  }

  async getSessionIds(id: string) {
    const pubKey = this.assetsService.getPublicKeyFromPrivateKey(this.assetsService.getPrivateKey());
    return await firstValueFrom(this.http.get<any>(environment.verifierConfig.host + '/sessions/' + pubKey + '/' + id, this.httpOptions));
  }
}
