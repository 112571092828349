import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AssetsComponent} from "./assets.component";
import {AssetsTransferInitiateComponent} from "./assets-transfer-initiate/assets-transfer-initiate.component";
import {OnboardingComponent} from "../onboarding/onboarding.component";

const routes: Routes = [
  {
    path: 'assets',
    children: [
      {
        path: '',
        component: AssetsComponent,
      },
      {
        path: 'onboarding',
        component: OnboardingComponent,
      },
      {
        path: 'transfer/:id',
        component: AssetsTransferInitiateComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AssetsRoutingModule {}
