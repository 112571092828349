import {Component, Inject, OnInit} from '@angular/core';
import { PresentationService } from '../presentation.service';
import { ContactsService } from '../../contacts/contacts.service';
import { IVerifiableCredential } from '@sphereon/ssi-types';
import {ActivatedRoute, Router} from '@angular/router';
import { CredentialsService } from '../credentials.service';
import { DisplayService } from '../display.service';
import { FormControl, Validators } from '@angular/forms';
import { CredentialsSupportedDisplay } from '@sphereon/oid4vci-common';
import { VerifiableCredentialsWithDefinition } from '@sphereon/ssi-sdk.siopv2-oid4vp-op-auth';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'ssi-credentials-select',
  templateUrl: './credentials-select.component.html',
  styleUrls: ['./credentials-select.component.scss'],
})
export class CredentialsSelectComponent implements OnInit {
  name?: string;
  responses: {
    purpose: string;
    credentials: {
      credential: IVerifiableCredential;
      issuer: string;
      display: CredentialsSupportedDisplay[];
    }[];
    control: FormControl;
  }[] = [];
  inDialog = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private dialogRef: MatDialogRef<CredentialsSelectComponent>,
    public presentationService: PresentationService,
    private contactsService: ContactsService,
    private router: Router,
    public credentialsService: CredentialsService,
    public displayService: DisplayService,
    public activatedRoute: ActivatedRoute
  ) {
    if (this.data) {
      this.inDialog = true;
    }
  }

  ngOnInit(): void {
    // this.name = this.contactsService.getByUrl(
    //   `${this.presentationService.url.protocol}//${this.presentationService.url.hostname}`
    // )?.name;
    this.name = this.presentationService.res!.client;
    this.responses = this.presentationService.res!.requests.map((request) => {
      const control = new FormControl('', [Validators.required]);
      return {
        control,
        purpose: request.purpose,
        credentials: request.value.map((credential) => {
          return {
            credential,
            display: this.displayService.getDisplay({
              types: credential.type as string[],
            }),
            issuer: this.contactsService.getNameByDid(
              credential.issuer as string
            ) as string,
          };
        }),
      };
    });
  }

  isValid() {
    return this.responses.every((response) => response.control.valid);
  }

  async accept() {
    const credentials = this.responses.map(
      (response) => response.control.value
    );

    const res: VerifiableCredentialsWithDefinition = {
      definition: this.presentationService.res!.definition,
      credentials,
    };
    await this.presentationService.sendResponse([res]);

    if (this.inDialog){
      this.dialogRef.close(true);
    } else{
      this.router.navigate(['/']);
    }
  }

  decline() {
    this.presentationService.logRequest();
    if (this.inDialog){
      this.dialogRef.close(false);
    }
  }
}
