<div *ngIf="verificationNeeded">
  <div *ngIf="!isMining">
    <h1 matDialogTitle>This transaction requires the verification of credentials.</h1>
    <ul>
      <li *ngIf="verificationInitiated">{{this.verificationInitiated}}</li>
      <li *ngIf="senderCredentialStatus">{{this.senderCredentialStatus}}</li>
      <li *ngIf="receiverCredentialStatus">{{this.receiverCredentialStatus}}</li>
      <li *ngIf="txAuthorized">{{this.txAuthorized}}</li>
    </ul>
  </div>
  <div *ngIf="isMining">
    <h1 matDialogTitle>Waiting for transaction...</h1>
    <mat-spinner style="align:center;"></mat-spinner>
  </div>
</div>
<div *ngIf="!verificationNeeded">
  <div *ngIf="isMining">
    <h1 matDialogTitle>Waiting for transaction...</h1>
    <mat-spinner style="align:center;"></mat-spinner>
  </div>

  <div *ngIf="!isMining">
    <h1 matDialogTitle>Transaction {{result}}</h1>
    <mat-dialog-content>
      <p *ngIf="result === 'successful'">
        <b>Hash:</b> {{txHash}}
      </p>
      <p *ngIf="result !== 'successful'">
        Please check your input again and make sure that you can make this transaction.
      </p>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-button type="submit" color="primary" (click)="close()">Close</button>
    </mat-dialog-actions>
  </div>
</div>


