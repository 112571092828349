import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {OnboardingComponent} from "./onboarding.component";

const routes: Routes = [
  {
    path: 'onboarding',
    children: [
      {
        path: '',
        component: OnboardingComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OnboardingRoutingModule {}
