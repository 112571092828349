<div id="content">
  <router-outlet></router-outlet>
</div>
<mat-toolbar class="toolbarNav">
  <div
    routerLink="/assets"
    routerLinkActive="active-link"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <mat-icon>account_balance_wallet</mat-icon>
    <span class="info">Assets</span>
  </div>
  <div
    routerLink="/scan"
    routerLinkActive="active-link"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <mat-icon>qr_code_scanner</mat-icon>
    <span class="info">Scan</span>
  </div>
  <div
    routerLink="/credentials"
    routerLinkActive="active-link"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <mat-icon>credit_card</mat-icon>
    <span class="info">Credentials</span>
  </div>
  <div
    routerLink="/contacts"
    routerLinkActive="active-link"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <mat-icon>share</mat-icon>
    <span class="info">Contacts</span>
  </div>
  <div
    routerLink="/settings"
    routerLinkActive="active-link"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <mat-icon>settings</mat-icon>
    <span class="info">Settings</span>
  </div>
</mat-toolbar>
