<mat-toolbar
  color="primary"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  Onboarding
</mat-toolbar>

<mat-accordion>
  <div id="content" fxLayout="column" fxLayoutGap="16px">
    <h3>To be be able to use programmable money, you need to have a public-private key pair.</h3>
    <mat-grid-list cols="2" rowHeight="2:1">
      <mat-grid-tile>
        <button mat-button color="primary" (click)="openDialog()">
          I already have keys
        </button>
      </mat-grid-tile>
      <mat-grid-tile>
        <button mat-button color="primary" (click)="generateKeys()">
          Generate new keys
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</mat-accordion>
