import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { CredentialsService } from '../credentials/credentials.service';
import { CredCreated } from '../credentials/cred-created';
import { ActivatedRoute } from '@angular/router';
import { PresentationService } from '../credentials/presentation.service';

@Component({
  selector: 'ssi-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss'],
})

/* The ScanComponent class is responsible for scanning QR codes, handling camera selection, and parsing
the scanned QR code. */
export class ScanComponent implements OnDestroy {
  cameras: MediaDeviceInfo[] = [];
  activeCamera!: MediaDeviceInfo;
  found = false;

  @ViewChild('scanner', { static: false })
  scanner!: ZXingScannerComponent;
  credential?: CredCreated;

  /**
   * The constructor function takes in three parameters, initializes a variable "qr" with the value of
   * the fragment from the current route snapshot, and if "qr" is truthy, sets the "found" variable to
   * true and calls the "parseQRCode" function with "qr" as an argument.
   * @param {CredentialsService} credentialService - An instance of the CredentialsService class.
   * @param {ActivatedRoute} route - The `route` parameter is an instance of the `ActivatedRoute` class,
   * which is used to access the current route information. It provides access to the route parameters,
   * query parameters, and other route-related data.
   * @param {PresentationService} presentationService - This parameter is of type PresentationService and
   * is used to access the methods and properties of the PresentationService class within the
   * constructor.
   */
  constructor(
    public credentialService: CredentialsService,
    private route: ActivatedRoute,
    public presentationService: PresentationService
  ) {
    const qr = this.route.snapshot.fragment;
    if (qr) {
      this.found = true;
      this.parseQRCode(qr);
    }
  }

  /**
   * Destroy the credential and presentation service
   */
  ngOnDestroy(): void {
    delete this.credentialService.credential;
    delete this.presentationService.res;
  }

  /**
   * Sets the camera
   * @param event
   */
  foundCameras(event: MediaDeviceInfo[]) {
    this.cameras = event;
    const selected = localStorage.getItem('camera');
    if (selected) {
      const found = this.cameras.find((c) => c.deviceId === selected);
      if (found && this.scanner.device !== found) {
        this.scanner.askForPermission().then(() => this.changeCamera(found));
      }
    } else {
      this.scanner.askForPermission().then(() => this.changeCamera(event[0]));
    }
  }

  /**
   * The function "changeCamera" sets the active camera and saves its device ID in the local storage.
   * @param {MediaDeviceInfo} camera - The `camera` parameter is of type `MediaDeviceInfo`. It represents
   * the selected camera device and contains information about the device, such as its `deviceId`.
   */
  changeCamera(camera: MediaDeviceInfo) {
    localStorage.setItem('camera', camera.deviceId);
    this.activeCamera = camera;
  }

  /**
   * The "scanned" function stops the scanner, sets a flag to indicate that a QR code has been found,
   * and then calls a function to parse the QR code.
   * @param {string} event - The "event" parameter is a string that represents the scanned QR code.
   */
  scanned(event: string) {
    this.scanner.scanStop();
    this.found = true;
    this.parseQRCode(event);
  }

  /**
   * The function `parseQRCode` checks the type of a QR code and calls different services based on the
   * type.
   * @param {string} qr - The `qr` parameter is a string that represents a QR code.
   */
  parseQRCode(qr: string) {

    const searchStr = '/scan';
    const index = qr.indexOf(searchStr);
    const qrCorrected = 'https://wallet.pm.yanacocha.fit.fraunhofer.de' + qr.substring(index);
    if(qrCorrected.includes('openid-credential-offer')){
      this.credentialService.requestCredential(qrCorrected);
    } else if (qrCorrected.includes('openid-vc')){
      this.presentationService.authRequest(qrCorrected);
    } else {
      alert('invalid QR code');
    }
    // if (qr.startsWith('openid-credential-offer')) {
    //   this.credentialService.requestCredential(qr);
    // } else if (qr.startsWith('web+openidcredentialoffer')) {
    //   this.credentialService.requestCredential(
    //     qr.replace('web+openidcredentialoffer', 'openid-credential-offer')
    //   );
    // } else if (qr.startsWith('openid-vc')) {
    //   this.presentationService.authRequest(qr);
    // } else if (qr.startsWith('web+openidvc')) {
    //   this.presentationService.authRequest(
    //     qr.replace('web+openidvc', 'openid-vc')
    //   );
    // } else {
    //   alert('invalid QR code');
    // }
  }

  /**
   * The function "noCamerasFound" displays an alert message indicating that no camera was found to
   * initialize the QR scanner.
   */
  noCamerasFound() {
    alert('no camera found to init the QR scanner');
  }
}
