import { Component, OnInit } from '@angular/core';
import { AssetsService, Asset } from '../assets.service';
import { Router } from "@angular/router";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {firstValueFrom} from "rxjs";
import {ImportKeysComponent} from "./import-keys/import-keys.component";
import {MessagingService} from "../messaging.service";

@Component({
  selector: 'ssi-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {
  form: FormGroup;
  key!: string;
  assets!: Asset[];

  constructor(
    private assetsService: AssetsService,
    private messagingService: MessagingService,
    private router: Router,
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) {
    this.form = this.formBuilder.group({
      newKey: new FormControl('', Validators.required),
    });
  }

  async ngOnInit() {
    this.key = this.assetsService.getPrivateKey();
    if (this.key) {
      this.router.navigate(['/assets']);
    }
  }

  async openDialog(){
    const closed = await firstValueFrom(
      this.dialog
        .open(ImportKeysComponent, {
          data: {},
          disableClose: true,
        })
        .afterClosed()
    );
    // todo better error handling
    if (closed) {
      await this.finalizeOnboarding();
    }
  }

  public async generateKeys() {
    this.assetsService.setPrivateKey(this.assetsService.generatePrivateKey());
    this.assetsService.setPublicKey();
    await this.finalizeOnboarding();
  }

  async finalizeOnboarding(){
    await this.messagingService.registerVerifier();
    console.log("Onboarding complete!");
    await this.router.navigate(['/assets']);
  }
}
