import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';
import bs58 from 'bs58'

@Injectable({
  providedIn: 'root',
})
export class IpfsService {
  url: string;
  key: string;
  secret: string;

  constructor() {
    this.url = `https://${environment.ipfsConfig.host}:${environment.ipfsConfig.port}`;
    this.key = environment.ipfsConfig.projectId;
    this.secret = environment.ipfsConfig.projectSecret;
  }

  public async getMeta(id: string): Promise<object> {
    const uri = this.getIpfsHashFromBytes32(id);
    const url = `${this.url}/api/v0/cat?arg=${uri}`;
    const headers = new Headers();
    headers.set('Authorization', 'Basic ' + Buffer.from(this.key + ":" + this.secret).toString('base64'));

    return fetch(url, {
      method: "POST",
      headers: headers,
      // body: JSON.stringify(data),
    }).then(res => res.json())
      .then(data => data)
      .catch(err => console.log(err));
  }

  public async setMeta(data: object) {
    const url = `${this.url}/api/v0/add`;
    const headers = new Headers();
    headers.set('Authorization', 'Basic ' + Buffer.from(this.key + ":" + this.secret).toString('base64'));

    const files = new FormData();
    files.append('file', JSON.stringify(data));

    return fetch(url, {
      method: "POST",
      headers: headers,
      body: files,
    }).then(res => res.json())
      .then(data => this.getBytes32FromIpfsHash(data.Hash))
      .catch(err => console.log(err));
  }

  private getBytes32FromIpfsHash(uri: string) {
    return '0x' +  Buffer.from(bs58.decode(uri).slice(2)).toString('hex');
  }

  private getIpfsHashFromBytes32(bytes32Hex: string) {
    const hashHex = '1220' + bytes32Hex.slice(2);
    const hashBytes = Buffer.from(hashHex, 'hex');
    const hashStr = bs58.encode(hashBytes);
    return hashStr;
  }
}
