import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetsComponent } from './assets.component';
import { AssetsService } from '../assets.service';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { SettingsModule } from '../settings/settings.module';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {SharedFrontendModule} from "@bcrl/shared-frontend";
import {AssetsTransferInitiateComponent} from "./assets-transfer-initiate/assets-transfer-initiate.component";
import {TransactionResultComponent} from "./transaction-result/transaction-result.component";
import {AssetsRoutingModule} from "./assets-routing.module";
import {MatSelectModule} from "@angular/material/select";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {
  MatCard,
  MatCardActions,
  MatCardContent,
  MatCardHeader,
  MatCardSubtitle,
  MatCardTitle, MatCardTitleGroup
} from "@angular/material/card";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef,
  MatTable
} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatStep, MatStepper} from "@angular/material/stepper";

@NgModule({
  declarations: [AssetsComponent, AssetsTransferInitiateComponent, TransactionResultComponent],
  providers: [AssetsService],
  imports: [
    CommonModule,
    SettingsModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    FlexLayoutModule,
    MatDialogModule,
    MatIconModule,
    MatExpansionModule,
    MatSlideToggleModule,
    SharedFrontendModule,
    AssetsRoutingModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatCard,
    MatCardHeader,
    MatCardContent,
    MatCardActions,
    MatCardTitle,
    MatCardSubtitle,
    MatCardTitleGroup,
    MatTable,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderCell,
    MatCell,
    MatColumnDef,
    MatHeaderRow,
    MatRow,
    MatHeaderRowDef,
    MatRowDef,
    MatPaginator,
    MatStepper,
    MatStep
  ],
  exports: [AssetsTransferInitiateComponent, TransactionResultComponent],
})
export class AssetsModule {}
