import {Component, ChangeDetectorRef} from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {firstValueFrom} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {TransactionResultComponent} from "../transaction-result/transaction-result.component";
import {NONE_TYPE} from "@angular/compiler";

@Component({
  selector: 'ssi-assets-transfer-initiate',
  templateUrl: './assets-transfer-initiate.component.html',
  styleUrls: ['./assets-transfer-initiate.component.scss'],
})
export class AssetsTransferInitiateComponent {
  form: FormGroup;
  id: string;
  isZeroToken: boolean;
  txConditions;
  displayedColumns: string[] = ['subject', 'name', 'predicate', 'value', 'actions'];
  existingLayers;
  predicates = [
    {
      "view": "greater than",
      "value": ">"
    },
    {
      "view": "greater than or equal to",
      "value": ">="
    },
    {
      "view": "less than",
      "value": "<"
    },
    {
      "view": "less than or equal to",
      "value": "<="
    },
    {
      "view": "equal to",
      "value": "="
    },
    {
      "view": "not equal to",
      "value": "!="
    },
  ];
  subjects = ["Sender", "Receiver"];

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef
  ) {
    this.id = this.route.snapshot.paramMap.get('id') as string;
    this.isZeroToken = this.id == '0x0000000000000000000000000000000000000000000000000000000000000000';
    this.txConditions = [];
    this.existingLayers = [];
    this.form = this.fb.group({
      to: new FormControl('', Validators.required),
      amount: new FormControl(NONE_TYPE, Validators.required),
      conditionLayerIndex: new FormControl(NONE_TYPE),
      subject: new FormControl(NONE_TYPE),
      name: new FormControl(''),
      predicate: new FormControl(''),
      value: new FormControl(''),
    });
  }

  addConditionLayer() {
    this.txConditions.push([]);
    this.existingLayers.push("Layer " + this.txConditions.length);
    this.cdRef.detectChanges();
  }

  addCondition() {
    const layerIndex = parseInt(this.form.get('conditionLayerIndex')?.value, 10)-1;
    this.txConditions.at(layerIndex).push({
      subject: this.form.get('subject')?.value,
      name: this.form.get('name')?.value,
      predicate: this.form.get('predicate')?.value,
      value: this.form.get('value')?.value
    });
    this.form.get('conditionLayerIndex')?.setValue(NONE_TYPE);
    this.form.get('subject')?.setValue(NONE_TYPE);
    this.form.get('name')?.setValue('');
    this.form.get('predicate')?.setValue(NONE_TYPE);
    this.form.get('value')?.setValue('');
    this.txConditions[layerIndex] = [...this.txConditions[layerIndex]];
    this.cdRef.detectChanges();
  }

  removeCondition(layerIndex: number, conditionIndex: number) {
    if (conditionIndex >= 0 && conditionIndex < this.txConditions.at(layerIndex).length) {
      this.txConditions.at(layerIndex).splice(conditionIndex, 1);
    }
    this.cdRef.detectChanges();
  }

  removeConditionLayer(layerIndex: number) {
    if (layerIndex >= 0 && layerIndex < this.txConditions.length) {
      this.txConditions.splice(layerIndex, 1);
      this.existingLayers.pop();
    }
    this.cdRef.detectChanges();
  }

  async initiate() {
    // todo more sanity checks for conditions (no contradicting values OR duplicate values)



    const closed = await firstValueFrom(
      this.dialog
        .open(TransactionResultComponent, {
          data: {
            to: this.form.get('to')?.value,
            id: this.id,
            amount: this.form.get('amount')?.value,
            conditions: this.txConditions
          } ,
          disableClose: true,
        })
        .afterClosed()
    );
    if (closed) {
      await this.router.navigate(['/assets']);
      // this.dialogRef.close(true);
    }
  }

  cancel() {
    this.router.navigate(['/assets']);
  }

}
