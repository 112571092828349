export const environment = {
  production: true,
  buildId: '{BUILD_ID}',
  polygonConfig: {
    network: 'https://polygon-amoy.infura.io/v3/47a6cfe491ac4ff3bf8284eeae3ec55b'
  },
  ipfsConfig: {
    host: 'ipfs.infura-ipfs.io',
    port: 5001,
    projectId: '47a6cfe491ac4ff3bf8284eeae3ec55b',
    projectSecret: 'ySkHpvGiP9sL6U0pvFDPN7j3qD6m1u7DcKnsYvAcPy3Isl9eWr9GFQ',
  },
  verifierConfig: {
    host: 'https://ovo.pm.yanacocha.fit.fraunhofer.de',
    username: 'pmovodemo',
    password: 'eSTernIalHeL',
  },
  authExpire: 240000,
  serverQueryRate: 1000
};
