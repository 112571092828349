import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {AssetsService} from "../../assets.service";
import {TransactionResponse} from "ethers";
import {IpfsService} from "../../ipfs.service";
import {BehaviorSubject, Subject, switchMap, takeUntil, timer} from "rxjs";
import {environment} from "../../../environments/environment";
import {MessagingService} from "../../messaging.service";

@Component({
  selector: 'ssi-transaction-result',
  templateUrl: './transaction-result.component.html',
  styleUrls: ['./transaction-result.component.scss'],
})
export class TransactionResultComponent {
  result: string = "";
  txHash: string = "";
  isMining: boolean = false;
  verificationNeeded: boolean = false;
  verificationInitiated: string | undefined;
  senderCredentialStatus: string | undefined;
  receiverCredentialStatus: string | undefined;
  txAuthorized: string | undefined;
  timerSrc:BehaviorSubject<number> = new BehaviorSubject<number>(0);  // <-- default time
  timerStop:Subject<any> = new Subject<void>();  // <-- use to close open observables

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TransactionResponse,
    private dialogRef: MatDialogRef<TransactionResultComponent>,
    private assetsService: AssetsService,
    private ipfsService: IpfsService,
    private messagingService: MessagingService,
  ) {
    this.transfer(this.data);
  }

  async close() {
    this.dialogRef.close(this.result == 'successful');
  }

  async transfer(data: any) {
    const id = data.id;
    const to = this.assetsService.getAddress(data.to);
    const amount = data.amount;
    const conditionsFiltered = data.conditions;
    let txReceipt;
    try {
      if (id === '0x0000000000000000000000000000000000000000000000000000000000000000') {
        this.verificationNeeded = false;
        if (conditionsFiltered.length == 0){
          txReceipt = await (await this.transferAssetWithoutConditions(to, id, amount)).wait();
        }else {
          txReceipt = await (await this.setConditions(to, amount, conditionsFiltered)).wait();
        }
        this.interpretTxReceipt(txReceipt);
      } else {
        this.verificationNeeded = true;
        await this.transferAssetWithConditions(to, id, amount);
      }
    } catch (e) {
      console.log(e);
      this.verificationNeeded=false;
      this.result = "failed";
      this.txHash = "";
    }
  }

  async setConditions(to: string, amount: number, conditions){
    const tokenURIs: any[] = []; // Initialize an array to store all tokenURIs

    // Process each list of conditions in reverse order
    for (let i = conditions.length - 1; i >= 0; i--) {
      // Sort the current list of conditions by the name property
      conditions[i].sort((a, b) => (a.name > b.name) ? 1 : -1);

      // Call setMeta with the current sorted list of conditions
      const currentTokenURI = await this.ipfsService.setMeta(conditions[i]);

      // Prepend the currentTokenURI to the tokenURIs array
      tokenURIs.unshift(currentTokenURI); // Ensures the latest tokenURI is always the first element

      // If there's a next list, add the currentTokenURI as an object to the next list
      if (i > 0) {
        conditions[i - 1].unshift({ nextConditionHash: currentTokenURI });
      }
    }

    const wallet = this.assetsService.getWalletWithProvider();
    const walletAddr = await wallet.getAddress();
    this.isMining = true;

    return await this.assetsService.call(
      'pm',
      'setSpendingConditions',
      [walletAddr, to, tokenURIs, amount]
    );
  };

  async transferAssetWithoutConditions(to: string, id: string, amount: number){
    const wallet = this.assetsService.getWalletWithProvider();
    const walletAddr = await wallet.getAddress();
    this.isMining = true;
    return await this.assetsService.call(
      'pm',
      'safeTransferFrom',
      [walletAddr, to, id, amount, this.assetsService.formatBytes32String('')]
    );
  };

  async transferAssetWithConditions(to: string, id: string, amount: number){
    const wallet = this.assetsService.getWalletWithProvider();
    const walletAddr = await wallet.getAddress();
    this.verificationInitiated = "Initiating verification...";

    const result: any = await this.messagingService.initiateTransaction(walletAddr, to, id);
    let sessionId: string;
    if(result.sessionId){
      this.verificationInitiated = "Verification process is initiated.";
      sessionId = result.sessionId;
    }else {
      throw new Error('Initiation failed');
    }
    let current = new Date();
    const startTimestamp = current.getTime();
    console.log("Starting querying OVO agent...");

    this.timerSrc.asObservable().pipe(
      takeUntil(this.timerStop),
      switchMap((time: number) => timer(time)),
      switchMap(() => this.messagingService.querySession(sessionId))
      ).subscribe({
        // @ts-ignore
        next: (response: any) => {
          try {
            switch (response['status']['txStatus']) {
              // auth tx idle
              case 0:
                if ('sender' in response['status']){
                  switch (response['status']['sender']) {
                    case 'waiting':
                      this.senderCredentialStatus = 'Waiting for sender credentials...'
                      break;
                    case 'verified':
                      this.senderCredentialStatus = 'Sender credentials verified.'
                      break;
                    case 'error':
                      throw new Error('Credential verification failed');
                    default:
                      break;
                  }
                }
                if ('receiver' in response['status']){
                  switch (response['status']['receiver']) {
                    case 'waiting':
                      this.receiverCredentialStatus = 'Waiting for receiver credentials...'
                      break;
                    case 'verified':
                      this.receiverCredentialStatus = 'Receiver credentials verified.'
                      break;
                    case 'error':
                      throw new Error('Credential verification failed');
                    default:
                      break;
                  }
                }
                break;
              // auth tx pending
              case 1:
                if ('sender' in response['status']) this.senderCredentialStatus = 'Sender credentials verified.';
                if ('receiver' in response['status']) this.receiverCredentialStatus = 'Receiver credentials verified.';
                this.txAuthorized = 'Waiting for authorization...'
                break;
              // auth tx successful
              case 2:
                this.txAuthorized = 'Authorization completed.'
                this.timerStop.next(0);
                return this.makeTransferTx(walletAddr, to, id, amount);
              // auth tx declined
              case 3:
                this.timerStop.next(0);
                throw new Error('Authentication transaction failed');
            }

            current = new Date();
            const currentTimestamp = current.getTime();
            if(currentTimestamp - startTimestamp > environment.authExpire){
              this.timerStop.next(0);
              throw new Error('Authentication time out');
            }else {
              // use response
              this.timerSrc.next(2000);  // <-- call again in 2 seconds
            }
          }catch (e) {
            this.verificationNeeded = false;
            console.log(e);
            this.result = "failed";
            this.txHash = "";
            this.isMining = false;
          }
        },
        error: (error: any) => {
          console.log(error);
          // handle error
        }
    });
  };

  async makeTransferTx(walletAddr:string, to:string, id: string, amount:number) {
    this.verificationNeeded = false;
    this.isMining = true;
    console.log("Authorization successful, making transfer tx...");
    try{
      const txReceipt = await(await this.assetsService.call(
        'pm',
        'safeTransferFrom',
        [walletAddr, to, id, amount, this.assetsService.formatBytes32String('')]
      )).wait();
      this.interpretTxReceipt(txReceipt);
    } catch (e) {
      console.log(e);
      this.result = "failed";
      this.txHash = "";
      this.isMining = false;
    }
  }

  interpretTxReceipt(txReceipt: { status: number; hash: string; }){
    this.result = txReceipt.status ? "successful" : "failed";
    this.txHash = txReceipt.hash;
    this.isMining = false;
  }
}
