<h1 matDialogTitle>Import private key</h1>

<form [formGroup]="form" (submit)="importKeys()">
  <mat-dialog-content>
    <p>
      Enter your private key below to import your keys.
    </p>
    <div fxLayout="column" fxLayoutAlign="start stretch">
      <mat-form-field [appearance]="'outline'">
        <mat-label>Private Key</mat-label>
        <input matInput placeholder="Private Keys" formControlName="newKey" />
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-button color="primary" type="submit" [disabled]="form.invalid">
      Import Keys
    </button>
  </mat-dialog-actions>
</form>
