import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {AssetsService} from "../../assets.service";

@Component({
  selector: 'ssi-import-keys',
  templateUrl: './import-keys.component.html',
  styleUrls: ['./import-keys.component.scss'],
})
export class ImportKeysComponent {
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private dialogRef: MatDialogRef<ImportKeysComponent>,
    private assetsService: AssetsService
  ) {
    this.form = new FormGroup({
      newKey: new FormControl('', Validators.required),
    });
  }

  importKeys() {
    const newKey= this.form.get('newKey')?.value;
    this.assetsService.setPrivateKey(newKey);
    this.assetsService.setPublicKey();
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
