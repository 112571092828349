import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetsService } from '../assets.service';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { SettingsModule } from '../settings/settings.module';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {SharedFrontendModule} from "@bcrl/shared-frontend";
import {OnboardingRoutingModule} from "./onboarding-routing.module";
import {MatSelectModule} from "@angular/material/select";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {OnboardingComponent} from "./onboarding.component";
import {MatGridList, MatGridTile} from "@angular/material/grid-list";
import {ImportKeysComponent} from "./import-keys/import-keys.component";

@NgModule({
  declarations: [OnboardingComponent, ImportKeysComponent],
  providers: [AssetsService],
  imports: [
    CommonModule,
    SettingsModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    FlexLayoutModule,
    MatDialogModule,
    MatIconModule,
    MatExpansionModule,
    MatSlideToggleModule,
    SharedFrontendModule,
    OnboardingRoutingModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatGridList,
    MatGridTile
  ],
  exports: [OnboardingComponent, ImportKeysComponent],
})
export class OnboardingModule {}
