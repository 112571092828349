import { Component, OnInit } from '@angular/core';
import { AssetsService, Asset } from '../assets.service';
import { Router } from "@angular/router";
import { IpfsService } from "../ipfs.service";
import { Condition } from '../objects';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MessagingService } from "../messaging.service";

@Component({
  selector: 'ssi-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss'],
})
export class AssetsComponent implements OnInit {
  form: FormGroup;
  key!: string;
  assets!: Asset[];
  zeroTokenId = '0x0000000000000000000000000000000000000000000000000000000000000000';

  constructor(
    private assetsService: AssetsService,
    private ipfsService: IpfsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private messagingService:MessagingService
  ) {
    this.form = this.formBuilder.group({
      newKey: new FormControl('', Validators.required),
    });
  }

  async ngOnInit() {
    this.key = this.assetsService.getPrivateKey();
    if (this.key) {
      this.assetsService.setPublicKey();
      this.assets = await this.getAllAssets();
    } else {
      this.router.navigate(['/onboarding']);
    }
  }

  public getPublicKey(){
    if (this.key) return this.assetsService.getPublicKeyFromPrivateKey(this.key);
    return '';
  }

  async getAllAssets() {
    const assetIdList = await this.assetsService.call('pm', 'assetsOf(address)', [this.getPublicKey()]);
    const assetBalanceList = await this.assetsService.call(
      'pm',
      'assetBalanceOf',
      [this.getPublicKey()]);
    const zeroBalance = await this.assetsService.call(
      'pm',
      'balanceOf',
      [this.getPublicKey(), this.zeroTokenId]
    );

    const assetList: any[] = [];
    for (let i = 0; i < assetIdList.length; i++) {
      if (assetBalanceList[i] == 0) continue;
      const [assetName, assetDesc] = await this.getAssetDetails(assetIdList[i]);
      assetList.push({id: assetIdList[i], name: assetName, desc: assetDesc, balance: assetBalanceList[i].toString()});
    }

    assetList.unshift({id: this.zeroTokenId, name: 'Zero Token', desc:'', balance: zeroBalance.toString()});
    return assetList;
  };

  async routeTransfer(id: string) {
    this.router.navigate(['/assets/transfer/' + id]);
  }

  async getAssetDetails(id: string){
    const name = "Asset: " + id.substring(0,7) + "..";
    let desc = "";
    let conditions: Condition[] = await this.ipfsService.getMeta(id) as Condition[];

    // const conditions: Condition[] = [];
    while (conditions == undefined) {
      await new Promise(r => setTimeout(r, 2000));
      conditions = await this.ipfsService.getMeta(id) as Condition[];
    }
    let hasChainedConditions = false;
    for (const cond of conditions) {
      if ('nextConditionHash' in cond) {
        hasChainedConditions = true;
        continue;
      }
      desc += cond.subject + ' ' + cond.name + cond.predicate + cond.value + ", ";
    }
    desc = desc.slice(0, -2);

    if (hasChainedConditions){
      desc += '; has further conditions for receiver to prove';
    }

    return [name, desc];
  }

  async getZeroTokens() {
    await this.assetsService.getZeroTokens('pm', 'buyProgrammableMoney(address,uint256)', [this.getPublicKey(), 100])
      .then(tx => {
        tx.wait().then( (result: { status: any; }) => {
          if (result.status) window.location.reload();
        })
      })
  }

}
