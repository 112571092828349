import { Route } from '@angular/router';
import {AssetsComponent} from "./assets/assets.component";
import { SettingsComponent } from './settings/settings.component';
import {OnboardingComponent} from "./onboarding/onboarding.component";

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: '/credentials',
    pathMatch: 'full',
  },
  {
    path: 'assets',
    component: AssetsComponent,
  },
  {
    path: 'onboarding',
    component: OnboardingComponent,
  },
  {
    path: 'settings',
    component: SettingsComponent,
  },
];
